import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import component from '@/components/common'
import './assets/scss/index.scss'
import './assets/iconfont/iconfont/iconfont.css'
import './assets/iconfont/fontAwesome/font-awesome.css'
import './plugins/element.js'
// import './mock/mock'
import './router/beforeEach'
import permission from './directive/permission/index'
Vue.prototype.$base_url = 'http://192.168.31.62:8081'
// Vue.prototype.$uploadHttp = 'http://192.168.10.75:8098/api/file/upload'
Vue.prototype.$uploadHttp = 'https://gr-mini.gdnandaedu.com/api/file/upload'
Vue.prototype.$fileHttp = 'https://minio-test.cx-online.net'
// Vue.prototype.$fileHttp = 'https://minio-test.cx-online.net'
// Vue.prototype.$fileHttp = 'https://gr-api.gdnandaedu.com/upload/image/'
Vue.config.productionTip = false

Vue.prototype.$moment = moment
moment.locale('zh-cn')
// 定义全局时间戳过滤器
Vue.filter('formatDate', (value) => moment(value).format('YYYY-MM-DD'))
Vue.filter('momentFormater', (dateStr, defaultFormat = 'YYYY-MM-DD HH:mm:ss') => moment(dateStr).format(defaultFormat))

Vue.use(component)
/* 全局自定义指令 */
Vue.use(permission)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
